html {
  font-size: 62.5%;
  font-family: Roboto, sans-serif;
  line-height: 1.15;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #2196f3;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 0.00938em;
}

a:hover {
  text-decoration: underline;
}

.section-active {
  background: rgba(93, 25, 22, 0.2);
}

.section-active:after {
  content: '';
  position: absolute;
  width: 100%;
  text-decoration: none;

  height: 2px;
  background: #8b0000;
  left: 0;
  bottom: 0;
  transform: translateY(0) !important;
  opacity: 1 !important;
}
